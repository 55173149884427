import Vue from "vue/dist/vue";
import axios from "axios";
import VueAxios from "vue-axios";

import config from "@/config";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
Vue.use(VueAxios, axios);

export default (objConfigParam) => {
  const objConfig = objConfigParam || {};

  let baseUrl = "";
  if (!config.api.baseUrl) {
    baseUrl += window.location.origin;
  }
  baseUrl += config.api.baseUrl;

  const instance = Vue.axios.create({
    withCredentials: true,
    baseURL: baseUrl,
    timeout: objConfig.timeout || 60000,
  });

  return instance;
};
