import Vue from "vue";

import CoreLdeVue from "@lde/core_lde_vue";

import i18n from "@/modules/i18n";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// Importe tous les composants génériques et les plugins de CoreLdeVue
Vue.use(CoreLdeVue);

export default new Vue({
  name: "Main",
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
