import i18n from "@/modules/i18n";
import config from "@/config";
import store from "@/store/index";

const legalRoutes = [
  {
    path: "/mentions-legales",
    meta: {
      name: "mentions_legales",
      title: i18n.t("legal.mentions-legales"),
      componentPrefix: "MentionsLegales",
      public: true,
    },
    component: () => import(
      /* webpackChunkName: "mentions_legales" */ "@/views/legal/mentions_legales/MentionsLegales.vue"
    ),
    children: [
      {
        path: "",
        name: "default_mentions_legales",
        redirect: { name: "mentions_legales_hub" },
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/cgu",
    meta: {
      name: "cgu",
      title: i18n.t("legal.cgu"),
      componentPrefix: "Cgu",
      public: true,
    },
    component: () => import(/* webpackChunkName: "cgu" */ "@/views/legal/cgu/Cgu.vue"),
    children: [
      {
        path: "",
        name: "default_cgu",
        redirect: { name: "cgu_hub" },
        meta: {
          public: true,
        },
      },
    ],
  },
];

legalRoutes.forEach((route) => {
  config.allServices.forEach((service) => {
    route.children.push({
      path: service.slug_service.replace("_", "-"),
      name: `${route.meta.name}_${service.slug_service}`,
      meta: {
        title: `${route.meta.title} - ${service[`nom${store.getters.country.toUpperCase()}`]}`,
        public: true,
      },
      component: () => import(
        /* webpackChunkName: "[request]" */
        `@/views/legal/${route.meta.name}/${route.meta.componentPrefix}${service.componentSuffix}.vue`
      ),
    });
  });
});

export default legalRoutes;
