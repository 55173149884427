import Vue from "vue";
import Vuex from "vuex";
import Api from "@/modules/axios";
import config from "@/config";
import { getCountry } from "@/config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogged: false,
    user: null,
    country: getCountry(),
    services: [],
    statutsServices: [],
    allServicesAreLoading: false,
    // TODO: Gérer les permissions
    permissions: [],
  },
  getters: {
    isLogged(state) {
      return state.isLogged;
    },
    user(state) {
      return state.user;
    },
    country(state) {
      return state.country;
    },
    services(state) {
      return state.services;
    },
    statutsServices(state) {
      return state.statutsServices;
    },
    allServicesAreLoading(state) {
      return state.allServicesAreLoading;
    },
    baseUrl(state) {
      return process.env[`VUE_APP_API_URL_${state.country.toUpperCase()}`];
    },
    hasPerm: (state) => (perm) => state.permissions.includes(perm),
  },
  mutations: {
    setLogged(state) {
      state.isLogged = true;
    },
    setUser(state, user) {
      state.user = user;
      if (user.avatar) {
        state.user.avatar = user.avatar;
      }
    },
    setCountry(state, country) {
      state.country = country;
    },
    setServices(state, services) {
      state.services = services;
    },
    setStatutsServices(state, statutsServices) {
      state.statutsServices = statutsServices;
    },
    setAllServicesAreLoading(state, allServicesAreLoading) {
      state.allServicesAreLoading = allServicesAreLoading;
    },
    setAvatar(state, { avatar, avatar_from_library: lib }) {
      state.user.avatar = avatar;
      state.user.avatar_from_library = lib;
    },
  },
  actions: {
    fetchServices(store) {
      return Api()
        .get("/service/")
        .then(({ data }) => {
          if (data.length === 1 && window.location.pathname === "/") {
            window.location = data[0].url;
          }
          store.commit("setServices", data);
        });
    },
    login(store) {
      store.commit("setAllServicesAreLoading", true);
      return Api({ noHeader: true })
        .get("/identify/")
        .then((res) => {
          store.commit("setUser", res.data.user);
          store.commit("setAllServicesAreLoading", false);
          store.dispatch("fetchServices");
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            window.location = config.loginUrl;
          }
        });
    },
  },
});
