const config = {
  supportedLocales: {
    fr: "Français",
    de: "Deutsch",
  },
};

export function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale = navigator.languages !== undefined
    ? navigator.languages[0]
    : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

/**
 * Nous dit si le local en paramètre est supporté par l'application.
 * @param {String} locale Code ISO de la langue.
 * @returns {Boolean} true si locale est supporté par l'application.
 * @public
 */
export function supportedLocalesInclude(locale) {
  return Object.keys(config.supportedLocales).includes(locale);
}
